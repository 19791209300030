export default function ValidateCNPJ(CNPJ: string) {
  if (!CNPJ) return;
  CNPJ = CNPJ.replace(/[^\d]+/g, '');
  if (CNPJ == '') return false;
  if (CNPJ.length != 14) {
    return false;
  }
  // Elimina CNPJs invalidos conhecidos
  if (
    CNPJ == '00000000000000' ||
    CNPJ == '11111111111111' ||
    CNPJ == '22222222222222' ||
    CNPJ == '33333333333333' ||
    CNPJ == '44444444444444' ||
    CNPJ == '55555555555555' ||
    CNPJ == '66666666666666' ||
    CNPJ == '77777777777777' ||
    CNPJ == '88888888888888' ||
    CNPJ == '99999999999999'
  ) {
    return false;
  }
  let tamanho = CNPJ.length - 2;
  let numeros = CNPJ.substring(0, tamanho);
  let digitos = CNPJ.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let index = tamanho; index >= 1; index--) {
    soma += parseInt(numeros.charAt(tamanho - index)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != parseInt(digitos.charAt(0))) {
    return false;
  }
  tamanho = tamanho + 1;
  numeros = CNPJ.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let index = tamanho; index >= 1; index--) {
    soma += parseInt(numeros.charAt(tamanho - index)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != parseInt(digitos.charAt(1))) {
    return false;
  }
  return true;
}
