import styled from 'styled-components';
import { Grid } from '@l_ine/core';

export const Divider = styled.div`
  background-color: ${(props) => props.theme.palette.primary.color};
  height: 4px;
  border-radius: 16px;
  width: 100px;
  margin: 16px 0 0 0;
`;

export const RememberGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const WelcomeTextGrid = styled(Grid)`
  padding: 16px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
