import React from 'react';

import validarCPF from '../validateCpf';
import ValidateCNPJ from '../validateCnpj';
import moment from 'moment';

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) =>
  (value != null || value != undefined) &&
  value.toString().length > 0 &&
  value.toString().trim().length > 0
    ? undefined
    : 'Obrigatório';

export const hourValidate = (value) =>
  (value != null || value != undefined) &&
  !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value.toString())
    ? undefined
    : 'Obrigatório';

export const requiredIsRange = (value) => {
  if (value && value.length > 0) {
    return (value[0] != null || value[0] != undefined) &&
      (value[1] != null || value[1] != undefined) &&
      moment(value[0], 'HH:mm', true).isValid() &&
      moment(value[1], 'HH:mm', true).isValid()
      ? undefined
      : 'Obrigatório';
  }
};

/**
 * @description Recebe um string e verifica se é menor que  "2100-01-01"
 * @param {sting} value String
 */
export const date2100 = (value) => {
  if (!value) return;

  return (value && !moment(value).isValid()) ||
    !(moment(value, 'DD/MM/YYYY').unix() < moment('01/01/2100').unix())
    ? 'O ano deve ser menor que 2100'
    : undefined;
};

export const validDate = (value) => {
  if (!value || (typeof value == 'string' && value.includes('Z'))) return;

  return !moment.isMoment(value) || (value._i && value?._i?.includes('_'))
    ? 'Data inválida'
    : undefined;
};
/**
 * Rules
 * @summary Rule to validate the maximum size of a string | Regra para validar o tamanho máximo de uma string
 * @param {number} max the string maxLength | O tamanho maximo  de uma string
 * @param {string} value  the string to validate | Valor para se verificar o tamanho string
 * @example {
 *  let str = "abcdefg"
 *  maxLength(10)(str)
 * }
 * @returns {string | undefined}
 */
export const maxLength = (max) => (value) =>
  value && String(value).length > max
    ? `Deve ter no máximo ${max} caracteres`
    : undefined;

export const length = (value) =>
  value && value.length ? undefined : 'Informe no mínimo um valor';

export const max2000 = maxLength(2000);
export const max500 = maxLength(500);
export const max255 = maxLength(255);
export const max200 = maxLength(200);
export const max160 = maxLength(160);
export const max156 = maxLength(156);
export const max150 = maxLength(150);
export const max100 = maxLength(100);
export const max60 = maxLength(60);
export const max44 = maxLength(44);
export const max40 = maxLength(40);
export const max30 = maxLength(30);
export const max20 = maxLength(20);
export const max15 = maxLength(15);
export const max14 = maxLength(14);
export const max12 = maxLength(12);
export const max11 = maxLength(11);
export const max10 = maxLength(10);
export const max9 = maxLength(9);
export const max8 = maxLength(8);
export const max7 = maxLength(7);
export const max6 = maxLength(6);
export const max5 = maxLength(5);
export const max4 = maxLength(4);
export const max3 = maxLength(3);
export const max2 = maxLength(2);

export const minLength = (min) => (value) =>
  value && value?.trim()?.length < min
    ? `Deve ter no mínimo ${min} caracteres`
    : undefined;

export const min1 = minLength(1);
export const min2 = minLength(2);
export const min3 = minLength(3);
export const min4 = minLength(4);
export const min5 = minLength(5);
export const min6 = minLength(6);
export const min7 = minLength(7);
export const min8 = minLength(8);
export const min9 = minLength(9);
export const min10 = minLength(10);
export const min12 = minLength(12);
export const min14 = minLength(14);
export const min15 = minLength(15);

export const number = (value) =>
  value && !Number(value) ? 'Número inválido' : undefined;

export const onlynumber = (value) =>
  value && isNaN(value) ? 'Apenas números' : undefined;

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const email = (value) =>
  value && (!emailRegex.test(value) ? 'E-mail inválido' : undefined);

export const emailsArr = (values) => {
  if (values && values.length > 0) {
    for (const value of values) {
      if (!emailRegex.test(value)) {
        return 'E-mail inválido';
      } else {
        return undefined;
      }
    }
  }
};

export const cpf = (value) => (!validarCPF(value) ? 'CPF inválido' : undefined);

export const cnpj = (value) => (!ValidateCNPJ(value) ? 'CNPJ inválido' : undefined);

export const cpfCnpj = (value) =>
  value && value.length > 11
    ? !ValidateCNPJ(value)
      ? 'CNPJ inválido'
      : undefined
    : !validarCPF(value)
    ? 'CPF inválido'
    : undefined;

export const quantidade = (value) =>
  parseFloat(value) == 0 ? 'Quantidade inválida' : undefined;

export const telefone = (value) =>
  value && String(value).length > 0 && String(value).length < 10
    ? 'Telefone inválido'
    : undefined;

export const cidade = (value) => (!value ? 'Cidade inválida' : undefined);

export const cep = (value) =>
  value && !/^[0-9]{8}$/.test(value.toString().replace('-', ''))
    ? 'CEP inválido'
    : undefined;

export const placa = (value) =>
  value && !/^[a-zA-Z]{3}[0-9]{1}[a-zA-Z0-9]{1}[0-9]{2}$/.test(value)
    ? 'Placa inválida'
    : undefined;
