import React from 'react';

//UI
import { Button, Grid, Typography, colorPalette, Field } from '@l_ine/core';

//REDUX
import { connect } from 'react-redux';
import AuthActions from '../../redux/auth/actions';
import AppActions from '../../redux/app/actions';

//NEXT
import Router, { useRouter } from 'next/router';
import { required, email, composeValidators } from '../../utils/rules';
import LoginLayout from '../../components/LoginLayout';
import * as styled from './index.style';
import { OnChange } from 'react-final-form-listeners';
import { randomString } from '../../utils/AllUtils';

function SignIn(props: any) {
  const router = useRouter();
  const logo_icfc = '../../../static/images/logo_icfc.png';

  const Form = (props: any) => {
    return (
      <form onSubmit={() => {}} style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <styled.WelcomeTextGrid item xs={12}>
            <Typography variant='titleLG'>Seja bem vindo ao iCFC</Typography>
            <styled.Divider />
          </styled.WelcomeTextGrid>
          <Grid item xs={12} style={{ padding: '16px' }}>
            <Field
              label='E-mail'
              name='email'
              fieldsize='md'
              required
              validate={composeValidators(email, required)}
              inputProps={{
                autocomplete: randomString(),
                'data-testid': 'signin--field-email',
              }}
            />
            <OnChange name='email'>
              {(value: string) => {
                props.form.change('email', value.toLowerCase());
              }}
            </OnChange>
          </Grid>
          <Grid item xs={12} style={{ padding: '16px' }}>
            <Field
              label='Senha'
              name='password'
              mode='password'
              fieldsize='md'
              required
              validate={required}
              inputProps={{
                autocomplete: randomString(),
                'data-testid': 'signin--field-senha',
              }}
            />
          </Grid>
          <styled.RememberGrid item xs={12} style={{ padding: '16px' }}>
            <Field
              name='remember'
              fieldsize='md'
              label='Manter conectado'
              mode='checkbox'
              type='checkbox'
              inputProps={{
                autocomplete: randomString(),
                'data-testid': 'signin--field-manterConectado',
              }}
            />
            <a
              data-testid='signin--button-esqueceSuaSenha'
              onClick={() =>
                router.push(
                  `/forgotPassword/Email?icon=${
                    router.query && router.query.icon ? router.query.icon : logo_icfc
                  }&url=${
                    router.query && router.query.url ? router.query.url : null
                  }`
                )
              }
            >
              <Typography
                variant='bodyXS'
                align='end'
                style={{
                  cursor: 'pointer',
                  color: colorPalette.neutral[100],
                }}
              >
                Esqueceu sua senha?
              </Typography>
            </a>
          </styled.RememberGrid>

          <Grid item xs={12} style={{ padding: '16px' }}>
            <Button
              data-testid='signin--button-entrar'
              size='lg'
              type='submit'
              fullWidth
            >
              Entrar
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  const onSubmitForm = async (values: any) => {
    if (values.password && ('' + values.password).length < 6) {
      props.toggleSnackBar({
        activated: true,
        title: 'Aviso',
        message: 'Email/Senha inválido',
        variant: 'danger',
      });
    } else {
      const aux = {
        ...values,
        url: Router.query && Router.query.url ? Router.query.url : null,
      };
      props.login(aux);
    }
  };

  return (
    <>
      <LoginLayout onSubmit={onSubmitForm} form={<Form />} />
    </>
  );
}

const { login, fetchTokenData } = AuthActions;
const { toggleSnackBar } = AppActions;

export default connect(null, {
  login,
  toggleSnackBar,
  fetchTokenData,
})(SignIn);
