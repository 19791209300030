import styled from 'styled-components';
import { Grid } from '@l_ine/core';

export const FormGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  @media (min-width: 960px) {
    width: 65%;
  }
  margin-top: auto;
`;

export const FooterWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 50px;
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  background-color: ${(props) => props.theme.palette.primary.color};
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const BannerImg = styled.img`
  height: auto;
  align-self: flex-end;
`;

export const BannerLogo = styled.img`
  height: auto;
  max-width: 200px;
  @media (max-width: 1280px) {
    max-width: 175px;
  }
`;

export const BannerText = styled.h3`
  width: 290px;
  color: white;
  font-size: 24px;
  line-height: 36px;
  font-weight: normal;

  > span {
    background: black;
    color: white;
    padding: 4px;
    border-radius: 4px;
    margin-left: 8px;
  }
`;
