import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Alert, Loading } from '../../../components';
import themes from '../../../config/themes';
import authAction from '../../../redux/auth/actions';
import { LayoutProps } from './@types';

const { logout } = authAction;

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <div>
      <header>
        <ThemeProvider theme={themes['themedefault']}>
          <>
            <Alert />
            {props.App.loading && <Loading />}
            {props.children}
          </>
        </ThemeProvider>
      </header>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.AuthReducer,
  App: state.AppReducer,
});
export default connect(mapStateToProps, { logout })(Layout);
