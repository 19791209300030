import React from 'react';
import Layout from '../../components/Layouts/NonAuthenticated';

const withLayout = (ComposedComponent: any) => (props: any) => {
  return (
    <Layout>
      <ComposedComponent {...props} />
    </Layout>
  );
};

export default withLayout;
