import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';

import * as styled from './index.style';

import { Typography, Grid } from '@l_ine/core';
import Router from 'next/router';

import { utils } from '@l_ine/core';
import { siteConfig } from '../../config';

import {
  FormApi,
  SubmissionErrors,
  ValidationErrors,
} from 'final-form';

interface LayoutProps {
  bannerSrc?: string;
  bannerComponent?: React.ReactElement | null;
  bannerAlt?: string;
  form: React.ReactElement;
  initialValues?: any;
  onSubmit: (
    values: any,
    form: FormApi<any, any>,
    callback?: (errors?: SubmissionErrors) => void
  ) =>
    | SubmissionErrors
    | Promise<SubmissionErrors | undefined>
    | undefined
    | void;
  validate?: (
    values: any
  ) => ValidationErrors | Promise<ValidationErrors> | undefined;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { width } = utils.useWindowSize();
  const { form, onSubmit, initialValues, validate, spacing } = props;

  const footerText = siteConfig.footerText;
  const banner = '/static/images/home.png';
  const [logo, setLogo] = useState('/static/images/logo_icfc.png');

  useEffect(() => {
    if (
      Router &&
      Router.query &&
      Router.query.icon &&
      !Router.query.icon?.includes('null')
    ) {
      setLogo(Router.query.icon as string);
    }
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <Grid
              container
              spacing={spacing ? spacing : 0}
              style={{ height: '100vh' }}
            >
              {width > 960 && (
                <Grid item xs={6}>
                  <styled.Container>
                    <div>
                      <styled.BannerLogo
                        src={logo}
                        alt='image'
                        id='logo'
                      />
                      <styled.BannerText>
                        Inovação, Inteligência e Interação para seu
                        CFC
                      </styled.BannerText>
                    </div>
                    <styled.BannerImg src={banner} alt='Banner' />
                  </styled.Container>
                </Grid>
              )}
              <styled.FormGrid item xs={width > 960 ? 6 : 12}>
                <styled.FormWrapper>
                  {React.cloneElement(form, { ...formProps })}
                </styled.FormWrapper>
                <styled.FooterWrapper>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography
                      variant='bodySM'
                      noWrap={true}
                      style={{
                        color: 'rgba(75,75,75,0.5)',
                      }}
                    >
                      {footerText}
                    </Typography>
                  </Grid>
                </styled.FooterWrapper>
              </styled.FormGrid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default Layout;
